import { firestore } from 'firebase/app';
import { useLayoutEffect, useMemo, useState } from 'react';
import { collectionFromSnapshot, Doc, toDoc } from '../types/Document';
import { LoadingValue } from './db';

type Listener<T> = (self: DocumentListener<T>) => void;

export class DocumentListener<T> {
  initialising: boolean = true;
  value: Doc<T> | null | void = undefined;
  error: any | undefined;
  detach: () => void;

  private listeners: Listener<T>[] = [];

  constructor(
    ref: firestore.DocumentReference,
    normalize: (
      s: firestore.QueryDocumentSnapshot | firestore.DocumentSnapshot
    ) => Doc<T> = (t) => toDoc<T>(t),
    defaultValue?: () => T
  ) {
    this.detach = ref.onSnapshot(
      (snapshot) => {
        this.value = snapshot.exists
          ? normalize(snapshot)
          : defaultValue
          ? {
              id: snapshot.id,
              collection: collectionFromSnapshot(snapshot),
              data: defaultValue()
            }
          : null;
        this.error = undefined;
        this.initialising = false;
        this.notify();
      },
      (error) => {
        this.value = undefined;
        this.error = error;
        this.initialising = false;
        this.notify();
      }
    );
  }

  private notify = () => this.listeners.forEach((l) => l(this));

  listen = (listener: Listener<T>) => {
    this.listeners.push(listener);
    if (!this.initialising) {
      listener(this);
    }
    return () => {
      const i = this.listeners.indexOf(listener);
      if (i !== -1) {
        this.listeners.splice(i, 1);
      }
    };
  };

  get = () => {
    return new Promise<Doc<T> | null>((resolve, reject) => {
      const toResult = () => {
        if (this.error) {
          reject(this.error);
          return;
        }
        if (this.value === undefined) {
          // should never happen
          return;
        }
        resolve(this.value);
      };

      if (!this.initialising) {
        toResult();
        return;
      }
      const unlisten = this.listen(() => {
        unlisten();
        toResult();
      });
    });
  };
}

export type DocumentListenerStore<T> = (key: string) => DocumentListener<T>;

export const createDocumentListenerGetter = <T>(
  refFn: (key: string) => firestore.DocumentReference,
  normalize: (
    s: firestore.QueryDocumentSnapshot | firestore.DocumentSnapshot
  ) => Doc<T> = (t) => toDoc<T>(t),
  defaultValue?: (key: string) => T
): DocumentListenerStore<T> => {
  const caches: { [key: string]: DocumentListener<T> } = {};
  return (key: string) => {
    if (!caches[key]) {
      caches[key] = new DocumentListener<T>(
        refFn(key),
        normalize,
        defaultValue ? () => defaultValue(key) : undefined
      );
    }
    return caches[key];
  };
};

export const useDocumentListener = <T>(d: DocumentListener<T>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setV] = useState(0);
  useLayoutEffect(() => {
    return d.listen(() => {
      setV((x) => (x > 1000 ? 0 : x + 1));
    }); // prevent integer from ever overflowing... ;)
  }, [d]);
  return [d.value, d.initialising, d.error] as LoadingValue<Doc<T>>;
};

export const useDocumentListeners = <T>(ds: DocumentListener<T>[]) => {
  const [v, setV] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const error = useMemo(() => ds.find((d) => d.error), [ds, v]);
  const loading = !!ds.find((d) => d.initialising);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const vs = useMemo(() => ds.map((d) => d.value as Doc<T> | null), [ds, v]);
  useLayoutEffect(() => {
    const ls = ds.map((d) =>
      d.listen(() => {
        if (!ds.find((x) => x.initialising)) {
          setV((x) => (x > 1000 ? 0 : x + 1));
        }
      })
    );
    return () => ls.forEach((l) => l());
  }, [ds]);
  return [!loading && !error ? vs : undefined, loading, error] as LoadingValue<
    typeof vs
  >;
};

export const useDocumentListenersFromStore = <T>(
  store: DocumentListenerStore<T>,
  ids: string[]
) => {
  const listeners = useMemo(() => ids.map((id) => store(id)), [store, ids]);
  return useDocumentListeners(listeners);
};
