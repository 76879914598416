import { FS } from '../constants';
import { generateToDocFn } from '../types/Document';
import { BillingUser } from '../types/User';
import { store } from './db';
import { createDocumentListenerGetter } from './documentListener';

export const billingUsersCollection = () => store().collection(FS.billingUsers);
export const toBillingUserDoc = generateToDocFn<BillingUser>();

export const billingUserStore = createDocumentListenerGetter(
  (id) => billingUsersCollection().doc(id),
  toBillingUserDoc
);
