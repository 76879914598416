import React, { useState } from 'react';
import { Typography, Paper, Button, TextField } from '@material-ui/core';
import styled from '../styled';
import { SuccessMessage, WarningMessage } from './Alerts';
import { SUBSCRIBER_COUNT } from '../constants';

let gtag: any;

if (typeof window !== 'undefined') {
  gtag = (window as any).gtag;
} else {
  gtag = () => {};
}

const YOUR_FORM_ID = '1377786';
const YOUR_FORM_URL = `https://app.convertkit.com/forms/${YOUR_FORM_ID}/subscriptions`;

const FormWrapper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px;

  @media (max-width: 500px) {
    padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(2)}px;
  }
`;

const Content = styled('p')`
  text-align: left;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 2rem auto;

  @media (max-width: 500px) {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  a {
    border-bottom: 2px solid white;
  }

  a:focus {
    outline: blue;
  }
`;

const InnerFormWrapper = styled<'form', { stacked: boolean }>('form')`
  display: grid;
  grid-template-columns: ${(p) => (p.stacked ? '1fr 155px' : '3fr 2fr')};
  grid-row-gap: 24px;
  grid-column-gap: 12px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const InnerForm = ({ stacked = false }: { stacked?: boolean }) => {
  const [status, setStatus] = useState<string | null>(null);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);

    try {
      data.append('fields[source]', document.referrer);
      const response = await fetch(YOUR_FORM_URL, {
        method: 'post',
        body: data,
        headers: {
          accept: 'application/json'
        }
      });

      const json = await response.json();

      if (json.status === 'success') {
        setStatus('SUCCESS');
        try {
          gtag('event', 'Subscribe', {
            event_category: 'Newsletter',
            event_label: 'Email Course'
          });
        } catch (err) {}
        return;
      }

      setStatus('ERROR');
    } catch (err) {
      setStatus('ERROR');
    }
  };

  return (
    <div>
      <InnerFormWrapper
        stacked={stacked}
        action={YOUR_FORM_URL}
        method="post"
        onSubmit={handleSubmit}
      >
        {status !== 'SUCCESS' && (
          <>
            <TextField
              label="Your email"
              name="email_address"
              placeholder="Your email address"
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  color: 'black',
                  borderRadius: '8px'
                },
                disableUnderline: true
              }}
              required
              fullWidth
              type="email"
            />
            <Button
              size="large"
              style={{
                padding: '12px 24px',
                fontSize: '16px'
              }}
              color="primary"
              variant="contained"
              type="submit"
            >
              Get the course
            </Button>
          </>
        )}
      </InnerFormWrapper>
      {status === 'SUCCESS' && (
        <SuccessMessage>
          Please check your inbox to confirm your subscription!
        </SuccessMessage>
      )}
      {status === 'ERROR' && (
        <WarningMessage>
          There was an error trying to sign you up! Is your privacy browser
          extension possibly blocking the request to my newsletter provider? Try
          disabling it or signing up at{' '}
          <a href="https://bloggingfordevs.ck.page/signup" target="_blank">
            https://bloggingfordevs.ck.page/signup
          </a>
        </WarningMessage>
      )}
    </div>
  );
};

const FormHeadline = styled(Typography)`
  font-size: ${(p) => p.theme.typography.h5.fontSize};
  font-weight: 700;
`;

export const SubscriptionForm = ({
  title,
  description
}: {
  title?: string;
  description?: React.ReactNode;
}) => {
  return (
    <FormWrapper>
      <FormHeadline>
        {title || 'Get the "Blogging for Devs" free email course'}
      </FormHeadline>
      <Content>
        {description || (
          <span>
            Whether you're just starting out, trying to revive an existing blog,
            or want to get past a plateau, this course and newsletter is for
            you.
            <br />
            <br />
            Join over {SUBSCRIBER_COUNT} developers growing their blogs now:
          </span>
        )}
      </Content>
      <InnerForm />
    </FormWrapper>
  );
};
