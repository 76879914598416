import { createMuiTheme } from '@material-ui/core';
import { COLORS } from './colors';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato, sans-serif',
    subtitle1: {
      fontWeight: 700,
      fontSize: 18
    },
    subtitle2: {
      color: '#767E87'
    }
  },
  palette: {
    primary: COLORS.primary,
    grey: COLORS.grey
  },
  shape: {
    borderRadius: 8
  }
});

theme = {
  ...theme,
  overrides: {
    MuiTypography: {
      colorTextSecondary: {
        color: theme.palette.grey[300]
      }
    },
    MuiCard: {
      root: {
        padding: theme.spacing(2)
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#1e2129'
      }
    },
    MuiTouchRipple: {
      root: {
        marginRight: `0 !important`
      }
    },
    MuiButton: {
      root: {
        color: theme.palette.grey[300]
      },
      textSecondary: {
        color: '#ffadd2'
      },
      containedPrimary: {
        background: 'linear-gradient(45deg,#1890ff 30%,#40a9ff 90%)',
        boxShadow: '0 1px 5px 0px rgba(0,80,179,0.3)',
        fontWeight: 700,
        '&:disabled': {
          background: 'linear-gradient(45deg,#1890ff 30%,#40a9ff 90%)',
          fontWeight: 'normal',
          opacity: 0.5,
          color: '#FFF !important'
        },
        '&:hover': {
          background: 'linear-gradient(45deg,#0083fc 30%,#3c9ffb 90%)'
        }
      },
      label: {
        textTransform: 'initial'
      },
      text: {
        color: theme.palette.grey[300]
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiStepLabel: {
      label: {
        color: theme.palette.grey[400],
        fontSize: '1rem',
        '&.MuiStepLabel-active': {
          color: theme.palette.grey[100],
          fontWeight: 600
        },
        '&.MuiStepLabel-completed': {
          color: theme.palette.primary.main,
          fontWeight: 600
        }
      },
      active: {
        color: theme.palette.primary.dark
      }
    },
    MuiStepConnector: {
      line: {
        borderColor: theme.palette.grey[400]
      }
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing()
      }
    },
    MuiDialog: {
      paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
      }
    },
    MuiDialogTitle: {
      root: {
        '& h2': {
          fontWeight: 600
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing()
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0
        }
      },
      textColorPrimary: {
        color: '#FFF'
      }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(),
        color: 'inherit'
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '16px'
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854'
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#22262b',
        color: '#e3e9f0'
      },
      elevation1: {
        backgroundColor: '#22262b',
        color: '#e3e9f0',
        boxShadow: '0 1px 5px 0px rgba(0, 0, 0, 0.5)'
      },
      elevation3: {
        backgroundColor: '#22262b',
        color: '#e3e9f0',
        boxShadow: '0 1px 5px 0px rgba(0, 0, 0, 0.5)'
      },
      elevation8: {
        backgroundColor: '#22262b',
        color: '#e3e9f0',
        boxShadow: '0 5px 20px 0 rgb(0 0 0 / 55%)'
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 48,
        height: 48,
        border: `4px solid ${theme.palette.grey[600]} !important`
      },
      colorDefault: {
        color: '#e3e9f0',
        backgroundColor: '#1E2226'
      }
    },
    MuiTable: {
      stickyHeader: {
        backgroundColor: theme.palette.background.paper
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: theme.palette.background.paper
      }
    },
    // @ts-ignore
    MuiToggleButtonGroup: {
      root: {
        // border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius
      },
      selected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    // @ts-ignore
    MuiToggleButton: {
      root: {
        background: 'none',
        color: theme.palette.primary.main,
        border: 'none',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
          color: `${theme.palette.primary.contrastText} !important`
        }
      },
      selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`
      }
    },
    MuiFormHelperText: {
      root: {
        color: theme.palette.grey[300]
      }
    },
    MuiFormControl: {
      root: {
        '&:hover .MuiFormLabel-root': {
          color: `${theme.palette.primary.main}`
        },
        '&:hover .MuiFormLabel-root.Mui-disabled': {
          color: `${theme.palette.grey[300]} !important`
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: theme.palette.grey[300]
      },
      disabled: {
        color: `${theme.palette.grey[300]} !important`
      }
    },
    MuiSelect: {
      icon: {
        color: `${theme.palette.grey[300]} !important`
      }
    },
    MuiFilledInput: {
      root: {
        color: theme.palette.grey[100],
        '&.Mui-disabled .MuiFilledInput': {
          borderColor: `${theme.palette.grey[500]} !important`
        },
        '&:hover .MuiFilledInput-input': {
          borderColor: `${theme.palette.primary.main}`
        }
      },
      input: {
        border: `1px solid ${theme.palette.grey[300]}`,
        color: 'inherit',
        borderRadius: 8
      },
      multiline: {
        background: theme.palette.grey[700]
      }
    },
    MuiInputBase: {
      input: {
        color: theme.palette.grey[300]
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: theme.palette.grey[300]
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: theme.palette.grey[300]
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        color: theme.palette.grey[100],
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.grey[500]} !important`
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.primary.main}`
        }
      },
      input: {
        color: theme.palette.grey[100],
        background: theme.palette.grey[700],
        borderRadius: 8
      },
      multiline: {
        background: theme.palette.grey[700]
      },
      notchedOutline: {
        borderColor: `${theme.palette.grey[300]}`
      }
    },
    MuiSkeleton: {
      root: {
        backgroundColor: theme.palette.grey[600]
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

export default theme;
