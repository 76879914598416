import React from 'react';
import styled from '../styled';

export const AuthorBadge = styled('div')`
  font-size: 8px;
  line-height: 14px;
  padding: 4px 8px;
  background-color: ${(p) => p.theme.palette.primary.dark};
  border-radius: 4px;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 8px;
`;

const AuthorByline = styled<'div', { color: 'black' | 'blue' }>('div')`
  color: ${(p) =>
    p.color === 'black'
      ? p.theme.palette.grey[300]
      : p.theme.palette.grey[100]};
  font-size: 14px;
  line-height: 18px;

  a {
    font-size: 14px;
    color: ${(p) =>
      p.color === 'black'
        ? p.theme.palette.grey[300]
        : p.theme.palette.grey[100]} !important;
    border-bottom: 1px solid;
    line-height: inherit;

    &:hover {
      border-bottom: 3px solid;
    }
  }
`;

const AuthorMeta = styled('div')`
  display: flex;
  align-items: center;
`;

const AuthorWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const AuthorImg = styled<'img', { color: 'black' | 'blue' }>('img')`
  width: 48px;
  height: 48px;
  border: 4px solid ${(p) => (p.color === 'black' ? '#22262b' : '#0050B3')};
  border-radius: 100%;
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

const AuthorName = styled('div')`
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

export const Author = ({
  color,
  name,
  byline,
  img,
  badge
}: {
  color: 'black' | 'blue';
  name: string;
  byline: React.ReactNode;
  img: string;
  badge: React.ReactNode;
}) => (
  <AuthorWrapper>
    <AuthorImg alt={name} src={img} color={color} />
    <div>
      <AuthorMeta>
        <AuthorName>{name}</AuthorName> {badge}
      </AuthorMeta>
      <AuthorByline color={color}>{byline}</AuthorByline>
    </div>
  </AuthorWrapper>
);
