import { FS } from '../constants';
import { generateToDocFn } from '../types/Document';
import { User } from '../types/User';
import { store } from './db';
import { createDocumentListenerGetter } from './documentListener';
import { now } from './time';

export const userCollection = () => store().collection(FS.users);
export const toUserDoc = generateToDocFn<User>((u) => {
  u.joinDate = u.joinDate || now();
  u.location = u.location || '';
  u.headline = u.headline || '';
  u.bio = u.bio || '';
  u.avatarUrl = u.avatarUrl || '';
  u.circleUserId = u.circleUserId === undefined ? null : u.circleUserId;
  u.circlePublicUid = u.circlePublicUid || '';
  u.circleProfileUrl = u.circleProfileUrl || '';
  u.twitterUrl = u.twitterUrl || '';
  u.settings = u.settings || {
    showEmail: false
  };
  u.roles = u.roles || [];
  return u;
});

export const usersStore = createDocumentListenerGetter(
  (userId) => userCollection().doc(userId),
  toUserDoc
);

export const updateName = (
  id: string,
  name: { firstName: string; lastName: string }
) => {
  return userCollection().doc(id).update(name);
};

export const updateAvatarUrl = (id: string, avatarUrl: string) => {
  return userCollection().doc(id).update({ avatarUrl });
};

type UserUpdates = Omit<
  User,
  | 'joinDate'
  | 'email'
  | 'circleUserId'
  | 'circlePublicUid'
  | 'circleProfileUrl'
  | 'roles'
>;
export const updateProfile = (userId: string, profile: UserUpdates) => {
  const cleanedUpProfile: UserUpdates = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    location: profile.location,
    headline: profile.headline,
    bio: profile.bio,
    avatarUrl: profile.avatarUrl,
    twitterUrl: profile.twitterUrl,
    settings: profile.settings
  };
  return userCollection().doc(userId).update(cleanedUpProfile);
};
