import { useEffect } from 'react';

const insertScript = (
  src: string,
  id: string,
  parentElement: HTMLElement,
  callback: () => void
) => {
  const script = window.document.createElement('script');
  script.async = true;
  script.src = src;
  script.id = id;
  script.onload = () => {
    callback();
  };
  parentElement.appendChild(script);
  return script;
};

const removeScript = (id: string, parentElement: HTMLElement) => {
  const script = window.document.getElementById(id);
  if (script) {
    parentElement.removeChild(script);
  }
};

export const TwitterScript = ({
  id,
  callback = () => {}
}: {
  id: string;
  callback?: () => void;
}) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const document = window.document;

    insertScript(
      `https://platform.twitter.com/widgets.js`,
      `twitter-script`,
      document.body,
      callback
    );

    return () => removeScript(`twitter-script`, document.body);
  }, [id]);

  return null;
};
