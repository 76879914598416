import styled from '../styled';

const COPY_FONT_SIZE = '20px';
const COPY_LINE_HEIGHT = '40px';

export const Blockquote = styled('blockquote')`
  background: linear-gradient(130.46deg, #0050b3 4.34%, #1890ff 99.39%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px
    ${(p) => p.theme.spacing(4)}px;
  position: relative;
  z-index: 1;
  margin-bottom: 36px;

  &:after {
    content: '“';
    color: #191c20;
    font-size: 200px;
    font-family: Georgia, Serif;
    position: absolute;
    z-index: -1;
    top: -68px;
    left: 23px;
  }

  p {
    z-index: 1;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};
    color: ${(p) => p.theme.palette.grey[100]};
    margin-bottom: 24px;
  }
`;
