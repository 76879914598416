import styled from '../styled';

export const SuccessMessage = styled('div')`
  border-radius: 8px;
  background-color: #eaff8f;
  padding: 12px 24px;
  color: #254000;
  font-size: 1rem;

  a {
    border-bottom: 1px solid #254000;
  }

  code {
    display: inline;
    background-color: #bae637;
    color: #254000;
    padding: 2px 4px;
    border-radius: 8px;
  }
`;

export const WarningMessage = styled('div')`
  border-radius: 8px;
  background-color: #ffe58f;
  color: #613400;
  font-size: 1rem;
  padding: 12px 24px;

  a {
    border-bottom: 1px solid #613400;
  }
`;

export const ErrorMessage = styled('div')`
  border-radius: 8px;
  background-color: #ffa39e;
  color: #5c0011;
  font-size: 1rem;
  padding: 12px 24px;

  a {
    border-bottom: 1px solid #5c0011;
  }
`;

export const InfoMessage = styled('div')`
  border-radius: 8px;
  background-color: #bae7ff;
  color: #096dd9;
  font-size: 1rem;
  padding: 12px 24px;

  a {
    border-bottom: 1px solid #096dd9;
  }
`;
