import { auth } from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import { FS } from '../constants';
import { BillingUser, CurrentUser, User } from '../types/User';
import { logout } from './auth';
import { toBillingUserDoc } from './billingUser';
import { store } from './db';
import { toUserDoc } from './users';

interface ICurrentUserContext {
  user: CurrentUser | null;
  isAuthenticated: boolean;
  loadingUser: boolean;
}

const CurrentUserContext = React.createContext<ICurrentUserContext>({
  user: null,
  isAuthenticated: false,
  loadingUser: true
});

export const CurrentUserProvider: React.FC = ({ children }) => {
  const [authUser, setAuthUser] = useState<firebase.User | null>();
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [billingUser, setBillingUser] = useState<BillingUser | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    return auth().onAuthStateChanged((authUser) => {
      setAuthUser(authUser);

      if (authUser === null) {
        setLoadingUser(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!authUser) {
      setBillingUser(null);
      return;
    }

    return store()
      .collection(FS.billingUsers)
      .doc(authUser.uid)
      .onSnapshot((s) => {
        if (!s.exists) {
          setLoadingUser(false);
          logout();
          return;
        }
        setBillingUser(toBillingUserDoc(s).data);
      });
  }, [authUser]);

  useEffect(() => {
    if (!authUser) {
      setUser(null);
      return;
    }

    return store()
      .collection(FS.users)
      .doc(authUser.uid)
      .onSnapshot((s) => {
        if (!s.exists) {
          setLoadingUser(false);
          logout();
          return;
        }
        setUser(toUserDoc(s).data);
      });
  }, [authUser]);

  useEffect(() => {
    if (!authUser) {
      setIsAdmin(false);
      return;
    }

    authUser
      .getIdTokenResult()
      .then((result) => !!result.claims.admin)
      .catch(() => false)
      .then(setIsAdmin);
  });

  useEffect(() => {
    if (authUser && user && billingUser) {
      setCurrentUser({
        id: authUser.uid,
        authUser,
        user,
        billingUser,
        isAdmin
      });
      setLoadingUser(false);
    } else {
      setCurrentUser(null);
    }
  }, [authUser, user, billingUser, isAdmin]);

  return (
    <CurrentUserContext.Provider
      value={{
        user: currentUser,
        isAuthenticated: !!currentUser,
        loadingUser: loadingUser
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};
