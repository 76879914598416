import { firestore } from 'firebase/app';

export type DocReference = {
  id: string;
  collection: string;
};

export type Doc<T> = {
  id: string;
  collection: string;
  data: T;
};

export const toTimestamp = (
  t: { seconds: number; nanoseconds: number } | undefined
) => {
  return t ? new firestore.Timestamp(t.seconds, t.nanoseconds) : t;
};

export const collectionFromSnapshot = (
  d: firestore.QueryDocumentSnapshot | firestore.DocumentSnapshot
) => {
  return d.ref.path.substring(0, d.ref.path.length - d.id.length - 1);
};

export const toDoc = <T>(
  d: firestore.QueryDocumentSnapshot | firestore.DocumentSnapshot,
  normalize?: (data: T) => T
): Doc<T> => ({
  id: d.id,
  collection: collectionFromSnapshot(d),
  data: normalize ? normalize(d.data() as any) : (d.data() as T)
});

export const generateToDocFn = <T>(normalize?: (data: T) => T) => (
  d: firestore.QueryDocumentSnapshot | firestore.DocumentSnapshot
) => toDoc<T>(d, normalize);
