export const COLORS = {
  primary: {
    light: '#91d5ff',
    main: '#40a9ff',
    dark: '#1890ff',
    contrastText: '#fff'
  },
  secondary: {
    light: '#ff8cb3',
    dark: '#c51162',
    main: '#f50057',
    contrastText: '#fff'
  },
  success: {
    light: '#ADE488',
    dark: '#237804',
    main: '#52c41a',
    contrastText: '#fff'
  },
  pending: {
    light: '#F5E18C',
    dark: '#F6CC1B',
    main: '#F6CC1B',
    contrastText: '#fff'
  },
  error: {
    light: '#F7CACA',
    dark: '#DB4D4D',
    main: '#DB4D4D',
    contrastText: '#fff'
  },
  grey: {
    100: '#F4F8FC',
    200: '#E3E9F0',
    300: '#A4ACB6',
    400: '#767E87',
    500: '#3c424a',
    600: '#1E2226',
    700: '#22262B',
    800: '#0D0F11',
    900: '#000000'
  }
};
