import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import React from 'react';
import { CurrentUserProvider } from './src/services/currentUser';

const firebaseConfig = {
  apiKey: 'AIzaSyCzCYoPFfstWw5k9VzDAJ1O07kjNYuJVYo',
  authDomain: 'bloggingfordevs.firebaseapp.com',
  databaseURL: 'https://bloggingfordevs.firebaseio.com',
  projectId: 'bloggingfordevs',
  storageBucket: 'bloggingfordevs.appspot.com',
  messagingSenderId: '249797901571',
  appId: '1:249797901571:web:3c3428d395d29ee0607d36',
  measurementId: 'G-91XFEH2EJC'
};

firebase.initializeApp(firebaseConfig);

export const wrapRootElement = ({ element }) => (
  <CurrentUserProvider>{element}</CurrentUserProvider>
);

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (!prevRouterProps || !routerProps) {
    return true;
  }

  const { location: prevLocation } = prevRouterProps;
  const { location } = routerProps;

  const { pathname, hash } = location;
  const { pathname: prevPathname, hash: prevHash } = prevLocation;
  return pathname !== prevPathname || hash !== prevHash;
};
