import { auth } from 'firebase/app';

export const login = async (email: string, password: string) => {
  return auth().signInWithEmailAndPassword(email, password);
};

export const resetPassword = async (
  email: string,
  oldPassword: string,
  newPassword: string
) => {
  const cred = auth.EmailAuthProvider.credential(email, oldPassword);
  const { currentUser } = auth();
  if (!currentUser) {
    throw new Error('NO_LOGIN');
  }
  await currentUser.reauthenticateWithCredential(cred);
  await currentUser.updatePassword(newPassword);
};

export const logout = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  auth()
    .signOut()
    .then(() => {
      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
