import { firestore } from 'firebase/app';
import { DateTime } from 'luxon';

export const ONE_MINUTE = 1000 * 60;

export const now = (): firestore.Timestamp =>
  // firestore is not defined in tests - provide a mock Timestamp instead.
  firestore
    ? firestore.Timestamp.now()
    : {
        seconds: 0,
        nanoseconds: 0,
        toDate: () => new Date(),
        toMillis: () => 0,
        isEqual: (other) => other.toMillis() === 0,
        valueOf: () => ''
      };

export const fromDate = (d: Date) => firestore.Timestamp.fromDate(d);
export const fromMillis = (m: number) => firestore.Timestamp.fromMillis(m);

export const toDateKey = (date: DateTime) => {
  return date.toUTC().toISODate();
};

export const fromDateKey = (dateKey: string) => {
  return DateTime.fromFormat(`${dateKey} UTC`, 'yyyy-MM-dd z');
};
