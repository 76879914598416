import firebase from 'firebase/app';
import { chunk } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { Doc, DocReference } from '../types/Document';
export const store = () => firebase.firestore();
export const rdb = () => firebase.database();
export const storage = () => firebase.storage();
export const toRef = (...parts: string[]) => parts.join('/');

const BATCH_SIZE = 200;

export const executeInBatch = <T>(
  items: T[],
  collection: string,
  forEachItem: (
    batch: firebase.firestore.WriteBatch,
    collection: firebase.firestore.CollectionReference,
    item: T
  ) => void
) => {
  if (!items.length) {
    return Promise.resolve(items);
  }
  return Promise.all(
    chunk(items, BATCH_SIZE).map((ds) => {
      const batch = store().batch();
      const coll = store().collection(collection);
      ds.forEach((d) => {
        forEachItem(batch, coll, d);
      });
      return batch.commit();
    })
  ).then(() => items);
};

export const batchSet = <T extends {}>(collection: string, docs: Doc<T>[]) => {
  return executeInBatch(docs, collection, (batch, coll, d) => {
    batch.set(coll.doc(d.id), d.data);
  });
};

export const batchUpdate = <T extends {}>(
  collection: string,
  docs: Doc<Partial<T>>[]
) => {
  return executeInBatch(docs, collection, (batch, coll, d) => {
    batch.update(coll.doc(d.id), d.data);
  });
};

export const batchDelete = (collection: string, docIds: string[]) => {
  return executeInBatch(docIds, collection, (batch, coll, id) => {
    batch.delete(coll.doc(id));
  });
};

export const paginatedForEach = async (
  query: firebase.firestore.Query,
  limit: number,
  forEachBatch: (snapshot: firebase.firestore.QuerySnapshot) => Promise<any>,
  s:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
    | null
) => {
  const get = async (
    start:
      | firebase.firestore.QueryDocumentSnapshot
      | firebase.firestore.DocumentSnapshot
      | null
  ): Promise<any> => {
    const q = start ? query.limit(limit).startAfter(start) : query.limit(limit);
    return q.get().then(async (s) => {
      if (s.empty) {
        return;
      }
      await forEachBatch(s);
      const nextStart = s.docs[s.docs.length - 1];
      return get(nextStart);
    });
  };
  await get(s);
};

export type LoadingValue<T, E = any> = [void | T, boolean, E];
export type LoadingValueExtended<T, E = any, Y = any> = [
  void | T,
  boolean,
  E,
  Y
];
export type LoadingValueLike<T, E = any, Y = any> =
  | LoadingValue<T, E>
  | LoadingValueExtended<T, E, Y>;

const mapLoadingValue = <T, X, E>(
  loadingValue: LoadingValueLike<T, E>,
  mapFn: (value: T) => X
): LoadingValue<X, E> => {
  const [value, loading, error] = loadingValue;
  return [value !== undefined ? mapFn(value) : undefined, loading, error];
};

export const useMappedLoadingValue = <T, X, E>(
  loadingValue: LoadingValueLike<T, E>,
  mapFn: (value: T) => X,
  mutableMapFn?: boolean
): LoadingValue<X, E> => {
  const [value, loading, error] = loadingValue;
  const mapListener = mutableMapFn ? mapFn : null;
  return useMemo(() => {
    // console.log('remap');
    return [value !== undefined ? mapFn(value) : undefined, loading, error];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, loading, error, mapListener]);
};

export const isAnyLoading = (loadingValues: LoadingValueLike<any>[]) => {
  return loadingValues.map((l) => l[1]).reduce((m, x) => m || x, false);
};

export const findError = <E = any>(
  loadingValues: LoadingValueLike<any, E>[]
) => {
  return loadingValues.map((l) => l[2]).find((x) => x);
};

export const combineLoadingValues = <T1, T2>(
  val1: LoadingValueLike<T1>,
  val2: LoadingValueLike<T2>
): LoadingValue<[T1, T2]> => {
  const allVals = [val1, val2];
  const loading = isAnyLoading(allVals);
  const error = findError(allVals);
  const v1 = val1[0];
  const v2 = val2[0];
  const value: void | [T1, T2] =
    !loading && !error && v1 !== undefined && v2 !== undefined
      ? [v1, v2]
      : undefined;
  return [value, loading, error];
};

export const combineLoadingValues3 = <T1, T2, T3>(
  val1: LoadingValueLike<T1>,
  val2: LoadingValueLike<T2>,
  val3: LoadingValueLike<T3>
): LoadingValue<[T1, T2, T3]> => {
  const allVals = [val1, val2, val3];
  const loading = isAnyLoading(allVals);
  const error = findError(allVals);
  const v1 = val1[0];
  const v2 = val2[0];
  const v3 = val3[0];
  const value: void | [T1, T2, T3] =
    !loading &&
    !error &&
    v1 !== undefined &&
    v2 !== undefined &&
    v3 !== undefined
      ? [v1, v2, v3]
      : undefined;
  return [value, loading, error];
};

export const combineLoadingValues4 = <T1, T2, T3, T4>(
  val1: LoadingValueLike<T1>,
  val2: LoadingValueLike<T2>,
  val3: LoadingValueLike<T3>,
  val4: LoadingValueLike<T4>
): LoadingValue<[T1, T2, T3, T4]> => {
  const allVals = [val1, val2];
  const loading = isAnyLoading(allVals);
  const error = findError(allVals);
  const v1 = val1[0];
  const v2 = val2[0];
  const v3 = val3[0];
  const v4 = val4[0];
  const value: void | [T1, T2, T3, T4] =
    !loading &&
    !error &&
    v1 !== undefined &&
    v2 !== undefined &&
    v3 !== undefined &&
    v4 !== undefined
      ? [v1, v2, v3, v4]
      : undefined;
  return [value, loading, error];
};

export const useOnLoadingValueDone = <T>(
  loadingValue: LoadingValueLike<T>,
  onDone: (result: T) => void,
  onError?: (err: any) => void
) => {
  const running = useRef(false);
  useEffect(() => {
    const [data, loading, error] = loadingValue;
    if (loading) {
      if (running.current) {
        return;
      }
      running.current = true;
    } else {
      if (!running.current) {
        return;
      }
      if (data) {
        onDone(data);
      }
      if (error && onError) {
        onError(error);
      }
      running.current = false;
    }
  }, [loadingValue, onDone, onError]);
};

export const updateInTransaction = <T>(
  ref: firebase.firestore.DocumentReference,
  updateFn: (d: T | null) => Promise<Partial<T> | null>
) => {
  return store().runTransaction((t) =>
    t.get(ref).then(async (doc) => {
      const d = doc && doc.exists ? (doc.data() as T) || null : null;
      const nextD = await updateFn(d);
      if (!nextD) {
        return;
      }
      await t.update(ref, nextD);
    })
  );
};

export const refreshTimestamp = (d: any) => {
  if (d === null) {
    return null;
  }
  if (d instanceof firebase.firestore.Timestamp) {
    return d;
  }
  if (d._seconds !== undefined && d._nanoseconds !== undefined) {
    return new firebase.firestore.Timestamp(d._seconds, d._nanoseconds);
  }

  // Looks like firebase-admin stringifies to this format, whereas
  // the client SDK uses underscores. Great job, Google.
  if (d.seconds !== undefined && d.nanoseconds !== undefined) {
    return new firebase.firestore.Timestamp(d.seconds, d.nanoseconds);
  }
  return d;
};

const toDocRef = (doc: Doc<any>) =>
  store().collection(doc.collection).doc(doc.id);

export const fromDocRef = (d: DocReference) =>
  store().collection(d.collection).doc(d.id);

export const setDoc = async <T>(doc: Doc<T>) => {
  await toDocRef(doc).set(doc.data);
  return doc;
};

export const removeDoc = async <T>(doc: Doc<T>) => {
  await toDocRef(doc).delete();
  return doc;
};

export const updateDocFromPartial = async <T>(doc: Doc<Partial<T>>) => {
  await toDocRef(doc).update(doc.data);
  return doc;
};

export const updateDoc = async <T>(
  doc: Doc<T>,
  toPartial: (data: T) => Partial<T>
) => {
  await toDocRef(doc).update(toPartial(doc.data));
};

const batchDocs = async <T>(
  docs: Doc<T>[],
  operation: (batch: firebase.firestore.WriteBatch, d: Doc<T>) => void
) => {
  if (!docs.length) {
    return Promise.resolve(docs);
  }
  await Promise.all(
    chunk(docs, BATCH_SIZE).map((ds) => {
      const batch = store().batch();
      ds.forEach((d) => {
        operation(batch, d);
      });
      return batch.commit().then(() => `Committed ${BATCH_SIZE}`);
    })
  );
  return docs;
};

export const batchSetDocs = async (docs: Doc<any>[]) => {
  return batchDocs(docs, (batch, d) =>
    batch.set(store().collection(d.collection).doc(d.id), d.data)
  );
};

export const batchUpdateDocsFromPartials = async (
  docs: Doc<Partial<any>>[]
) => {
  return batchDocs(docs, (batch, d) =>
    batch.update(store().collection(d.collection).doc(d.id), d.data)
  );
};

export const batchUpdateDocs = async <T>(
  docs: Doc<T>[],
  toPartial: (data: T) => Partial<T>
) => {
  return batchDocs(docs, (batch, d) =>
    batch.update(store().collection(d.collection).doc(d.id), toPartial(d.data))
  );
};

export const batchDeleteDocs = async (docs: Doc<any>[]) => {
  return batchDocs(docs, (batch, d) =>
    batch.delete(store().collection(d.collection).doc(d.id))
  );
};

export async function* iterate<T = firebase.firestore.DocumentData>(
  query: firebase.firestore.Query<T>,
  startAt?: firebase.firestore.DocumentSnapshot
) {
  let nextStart = startAt;
  while (true) {
    const q = nextStart ? query.startAfter(nextStart) : query;
    const result = await q.get();
    if (result.empty) {
      return [];
    } else {
      yield result;
      nextStart = result.docs[result.docs.length - 1];
      console.log(
        `Result size: ${result.docs.length}, nextDoc: ${nextStart.ref.path}`
      );
    }
  }
}
